import { Experiment } from './flipper.types'

/**
 * This Experiments does...
 * RECORDED =
 * NOT RECORDED =
 */
export const fullStoryExisting: Experiment = {
  name: 'Full Story Existing',
  routes: /\[...slug\]/i,
  id: String(process.env.APP_FULLSTORY_EXISTING_EXPERIMENT_ID),
  names: {
    control: String(process.env.APP_FULLSTORY_EXISTING_RECORDED_ID),
    variant1: String(process.env.APP_FULLSTORY_EXISTING_NOTRECORDED_ID),
  },
  identifier: 'member',
  tenant: 'tk',
  firstTrigger: true,
}
/**
 * This Experiments does...
 * RECORDED =
 * NOT RECORDED =
 */
export const fullStoryNew: Experiment = {
  name: 'Full Story New',
  routes: /\[...slug\]/i,
  id: String(process.env.APP_FULLSTORY_NEW_EXPERIMENT_ID),
  names: {
    control: String(process.env.APP_FULLSTORY_NEW_RECORDED_ID),
    variant1: String(process.env.APP_FULLSTORY_NEW_NOTRECORDED_ID),
  },
  identifier: 'member',
  tenant: 'tk',
  firstTrigger: true,
}

export const APP_FILTERING_EXPERIMENT_ID = String(process.env.APP_FILTERING_EXPERIMENT_ID)
export const APP_FILTERING_CONTROL_ID = String(process.env.APP_FILTERING_CONTROL_ID)
export const APP_FILTERING_VARIANT_ID = String(process.env.APP_FILTERING_VARIANT_ID)

export const filtering: Experiment = {
  name: 'Results count and 0 results prevention',
  routes: /\[...slug\]/i,
  id: APP_FILTERING_EXPERIMENT_ID,
  names: {
    control: APP_FILTERING_CONTROL_ID,
    variant1: APP_FILTERING_VARIANT_ID,
  },
  identifier: 'both',
  tenant: 'tk',
  firstTrigger: true,
}

export const YOUR_DRAFTS_LANDING_PAGE_EXPERIMENT_ID = String(
  process.env.NEXT_PUBLIC_YOUR_DRAFTS_LANDING_PAGE_EXPERIMENT_ID
)
export const YOUR_DRAFTS_LANDING_PAGE_CONTROL_ID = String(process.env.NEXT_PUBLIC_YOUR_DRAFTS_LANDING_PAGE_CONTROL_ID)
export const YOUR_DRAFTS_LANDING_PAGE_VARIANT_ID = String(process.env.NEXT_PUBLIC_YOUR_DRAFTS_LANDING_PAGE_VARIANT_ID)

export const draftsLandingPageUpdates: Experiment = {
  name: 'Paper: Your Drafts Landing Page Updates',
  routes: /your-drafts/i,
  id: YOUR_DRAFTS_LANDING_PAGE_EXPERIMENT_ID,
  names: {
    control: YOUR_DRAFTS_LANDING_PAGE_CONTROL_ID,
    variant1: YOUR_DRAFTS_LANDING_PAGE_VARIANT_ID,
  },
  identifier: 'member',
  tenant: 'tk',
  firstTrigger: true,
}

export const APP_PDP_DESKTOP_CAROUSEL_V2_EXPERIMENT_ID = String(process.env.APP_PDP_DESKTOP_CAROUSEL_V2_EXPERIMENT_ID)
export const APP_PDP_DESKTOP_CAROUSEL_V2_CONTROL_ID = String(process.env.APP_PDP_DESKTOP_CAROUSEL_V2_CONTROL_ID)
export const APP_PDP_DESKTOP_CAROUSEL_V2_VARIANT_ID = String(process.env.APP_PDP_DESKTOP_CAROUSEL_V2_VARIANT_ID)

export const pdpDesktopCarouselV2: Experiment = {
  name: 'PDP Carousel Image Gallery Iteration 2',
  routes: /product/i,
  id: APP_PDP_DESKTOP_CAROUSEL_V2_EXPERIMENT_ID,
  names: {
    control: APP_PDP_DESKTOP_CAROUSEL_V2_CONTROL_ID,
    variant1: APP_PDP_DESKTOP_CAROUSEL_V2_VARIANT_ID,
  },
  identifier: 'guest',
  tenant: 'tk',
  firstTrigger: true,
}

export const RESURFACE_SEARCH_EXPERIMENT_ID = String(process.env.NEXT_PUBLIC_RESURFACE_SEARCH_EXPERIMENT_ID)
export const RESURFACE_SEARCH_CONTROL_ID = String(process.env.NEXT_PUBLIC_RESURFACE_SEARCH_CONTROL_ID)
export const RESURFACE_SEARCH_VARIANT_ID = String(process.env.NEXT_PUBLIC_RESURFACE_SEARCH_VARIANT_ID)

export const resurfaceSearchExperiment: Experiment = {
  name: 'Resurface Search on Paper',
  routes: /.*/i,
  id: RESURFACE_SEARCH_EXPERIMENT_ID,
  names: {
    control: RESURFACE_SEARCH_CONTROL_ID,
    variant1: RESURFACE_SEARCH_VARIANT_ID,
  },
  tenant: 'tk',
  identifier: 'guest',
  firstTrigger: true,
}

export const REVIEW_PRICE_EXPERIMENT_ID = String(process.env.NEXT_PUBLIC_REVIEW_PRICE_EXPERIMENT_ID)
export const REVIEW_PRICE_CONTROL_ID = String(process.env.NEXT_PUBLIC_REVIEW_PRICE_CONTROL_ID)
export const REVIEW_PRICE_VARIANT_ID = String(process.env.NEXT_PUBLIC_REVIEW_PRICE_VARIANT_ID)

export const reviewPrice: Experiment = {
  name: 'Review Price',
  routes: /review/i,
  id: REVIEW_PRICE_EXPERIMENT_ID,
  names: {
    control: REVIEW_PRICE_CONTROL_ID,
    variant1: REVIEW_PRICE_VARIANT_ID,
  },
  identifier: 'guest',
  tenant: 'tk',
  firstTrigger: true,
}

export const APP_PRICING_FILTER_POSITION_EXPERIMENT_ID = String(process.env.APP_PRICING_FILTER_POSITION_EXPERIMENT_ID)
export const APP_PRICING_FILTER_POSITION_CONTROL_ID = String(process.env.APP_PRICING_FILTER_POSITION_CONTROL_ID)
export const APP_PRICING_FILTER_POSITION_VARIANT_ID = String(process.env.APP_PRICING_FILTER_POSITION_VARIANT_ID)

export const pricingFilterPosition: Experiment = {
  name: 'Change Pricing Filter Position',
  routes: /\[...slug\]/i,
  id: APP_PRICING_FILTER_POSITION_EXPERIMENT_ID,
  names: {
    control: APP_PRICING_FILTER_POSITION_CONTROL_ID,
    variant1: APP_PRICING_FILTER_POSITION_VARIANT_ID,
  },
  identifier: 'guest',
  tenant: 'tk',
  firstTrigger: true,
}

export const APP_ACCESSORIES_MODAL_EXPERIMENT_ID = String(process.env.APP_ACCESSORIES_MODAL_EXPERIMENT_ID)
export const APP_ACCESSORIES_MODAL_CONTROL_ID = String(process.env.APP_ACCESSORIES_MODAL_CONTROL_ID)
export const APP_ACCESSORIES_MODAL_VARIANT_ID = String(process.env.APP_ACCESSORIES_MODAL_VARIANT_ID)

export const accessoriesModal: Experiment = {
  name: 'Accessories Iteration',
  routes: /(your-drafts|review)$/i,
  id: APP_ACCESSORIES_MODAL_EXPERIMENT_ID,
  names: {
    control: APP_ACCESSORIES_MODAL_CONTROL_ID,
    variant1: APP_ACCESSORIES_MODAL_VARIANT_ID,
  },
  identifier: 'guest',
  tenant: 'tk',
  firstTrigger: true,
}
