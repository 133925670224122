import Cookie from 'cookie'

import { Variants } from '@/lib/flipper/flipper.types'
import { getCartDraftsCounters } from '@/services/storefront/drafts/drafts'
import { FeatureFlags } from '@/services/unleash.service'

import type { SeoProps } from '@/data/types'
import type { UserProfile } from '@/wwBrand/modules/core/services/auth'
import type { IncomingHttpHeaders } from 'http'
import type { GetServerSidePropsContext } from 'next'
import { DeviceMode } from '../analytics/interfaces'

export interface PageError {
  message?: string
  errorCode: number
}

export interface WebviewSnippets {
  commerceDataSnippet: string
}

export interface ItemCounters {
  cart: number
  drafts: number
}

export const Layouts = {
  Web: 'Web',
  WebView: 'WebView',
}

export type LayoutTypes = keyof typeof Layouts

export type LayoutProps = {
  name: LayoutTypes
  itemCounters: ItemCounters
  snippets?: WebviewSnippets
}

export type CatalystHtmlProps =
  | {
      'data-tk-catalyst-variant'?: string
      className?: string
    }
  | undefined

export interface DefaultPageProps {
  deviceMode: DeviceMode
  error?: PageError | null
  seoTags?: SeoProps
  layout: LayoutProps
  userProfile?: UserProfile | null
  isApp?: boolean
  pageNumber?: number | null
  shouldReloadPage?: boolean
  variants?: Variants
  cookies?: string
  features: FeatureFlags
  cosmoboxProps: {
    brand: string
    country: string
  }
  referrer?: string
}

const hasWebViewCookie = (headers: IncomingHttpHeaders | undefined) => {
  if (headers) {
    const parsedCookie = Cookie.parse(headers?.cookie || '')

    return Boolean(parsedCookie['FROM_WEBVIEW'])
  }

  return false
}

export const isAndroidApp = (headers: IncomingHttpHeaders | undefined): boolean => {
  if (headers) {
    const { 'x-app-users-android': isAndroid, http_x_app_users_android: isAndroid2 } = headers

    return Boolean(isAndroid) || Boolean(isAndroid2) || hasWebViewCookie(headers)
  }

  return false
}

export const isiOSApp = (headers: IncomingHttpHeaders | undefined): boolean => {
  if (headers) {
    const { 'x-app-users-ios': isIOS, http_x_app_users_ios: isIOS2 } = headers

    return Boolean(isIOS) || Boolean(isIOS2) || hasWebViewCookie(headers)
  }

  return false
}

export const isIOSWebview = (headers?: IncomingHttpHeaders) => {
  const iOSWebviewUA = 'TKPlanner-iOS'

  const { 'user-agent': ua } = headers || {}

  return ua?.includes(iOSWebviewUA) ?? false
}

type WithServerSideProps<T> = (context: GetServerSidePropsContext) => Promise<{ props: T; redirect?: any }>
const withFn = <T>(callback: WithServerSideProps<T>) => {
  return <J>(nextCallback: WithServerSideProps<J>) => {
    return async (context: GetServerSidePropsContext) => {
      const [callbackProps, nextCallbackProps] = await Promise.all([
        await callback(context),
        await nextCallback(context),
      ])

      if (callbackProps?.redirect || nextCallbackProps?.redirect) {
        return {
          redirect: nextCallbackProps.redirect,
        }
      }

      return {
        props: {
          ...callbackProps?.props,
          ...nextCallbackProps?.props,
        },
      }
    }
  }
}

export const parseAnonymousUserToken = (storefrontTokenName: string, cookie?: string): string | undefined => {
  try {
    const parsedCookie = Cookie.parse(cookie)
    return parsedCookie[storefrontTokenName]
  } catch (error) {
    console.log(error)
  }
}

export const parseXOMembershipToken = (cookie?: string): string | undefined => {
  try {
    const { 'xo-session-token': token } = Cookie.parse(cookie)
    return token
  } catch (error) {
    console.log(error)
  }
}

const generateIOSSnippet = (headers: IncomingHttpHeaders, itemCounters: ItemCounters): string =>
  JSON.stringify({
    tkWebview: {
      count: {
        orders: 1, // Always Default to 1
        itemsInCart: itemCounters.cart,
      },

      drafts: {
        active: true,
        count: itemCounters.drafts,
      },
      userAgent: headers?.['user-agent'],
    },
  })

export async function getLayout(headers: IncomingHttpHeaders = {}): Promise<LayoutProps> {
  return {
    ...getLayoutType(headers),
    ...(await getIOSSnippet(headers)),
  }
}

export const getLayoutType = (headers: IncomingHttpHeaders = {}): { name: 'WebView' | 'Web' } => {
  const name = isIOSWebview(headers) ? 'WebView' : 'Web'

  return {
    name,
  }
}

export const getIOSSnippet = async (headers: IncomingHttpHeaders = {}) => {
  const name = isIOSWebview(headers) ? 'WebView' : 'Web'
  const isLoggedIn = parseXOMembershipToken(headers?.cookie || '')
  const includeDrafts = Boolean(+(process.env.FT_DRAFTS as string)) && !!isLoggedIn
  const itemCounters = await getCartDraftsCounters(includeDrafts, headers)
  const commerceDataSnippet = name === 'WebView' && generateIOSSnippet(headers, itemCounters)

  return {
    itemCounters,
    ...(commerceDataSnippet ? { snippets: { commerceDataSnippet } } : {}),
  }
}

/**
 * @deprecated
 * getLayout is being invoked in _app.tsx
 */
export const withLayout = withFn<{ layout: LayoutProps }>(async (context) => {
  const layout = await getLayout(context.req.headers)

  return {
    props: {
      layout,
    },
  }
})

export const isWeddingWireApp = (headers: IncomingHttpHeaders | undefined): boolean =>
  isAndroidApp(headers) || isiOSApp(headers) || hasWebViewCookie(headers)
